import styled, { css } from 'styled-components/macro';
import { media } from './sizes';
import { theme } from 'theme';
import { ReactComponent as Trash } from 'assets/Icons/trash.svg';

export const emojiStyle = {
  background: 'transparent',
  fontSize: '24px',
  marginTop: '-3px',
};

export const TechTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 344px;

  @media (max-width: 1010px) {
    min-width: 270px;
  }
`;

export const ProductsTitleWrapper = styled(TitleWrapper)`
  min-width: 144px;
  max-width: 380px;

  @media (max-width: 1010px) {
    min-width: 200px;
  }
`;

export const AnalyseId = styled.span<{ textLength?: number }>`
  padding: 4px;
  border-radius: 4px;
  border: 1px solid rgba(212, 222, 236, 0.6);
  background: #f4f5f7;
  font-weight: 400;
  cursor: pointer;
  position: relative;

  .tooltip {
    position: absolute;
    padding: 8px 10px;
    border-radius: 10px;
    top: 40px;
    left: 7px;
    text-align: left;
    font-size: 12px;
    display: none;
    z-index: 120;
    background: #2e3e5c;
    color: #fff;
    min-width: ${({ textLength }) =>
      textLength && textLength > 45 ? '200px' : 'auto'};
    white-space: ${({ textLength }) =>
      textLength && textLength > 45 ? 'wrap' : 'nowrap'};
    width: fit-content;
  }

  .tooltip-arrow {
    position: absolute;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    top: 37px;
    left: 15px;
    border-radius: 2px;
    z-index: 115;
    background: #2e3e5c;
    display: none;
  }

  &:hover {
    background: #2e3e5c;
    color: #fff;

    .tooltip,
    .tooltip-arrow {
      display: inline-block;
    }
  }
`;

export const IdsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;

  .multiselect-item {
    margin: 4px;
    margin-right: 12px;

    img {
      margin-right: 8px;
    }
  }
`;

export const ScAuthPage = styled.div`
  display: flex;
  align-items: stretch;
  min-height: 100vh;

  .authColumn {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 16px 16px 16px 8px;
    box-sizing: border-box;
    &:first-child {
      padding: 16px 8px 16px 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .container {
      flex: 0 0 100%;
      max-width: 100%;
      min-height: calc(100% - 76px);
    }

    img {
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 32px);
      object-fit: cover;
      border-radius: 40px;
      vertical-align: bottom;
    }
  }

  @media (${media.mobileS}) {
    .authColumn {
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }
    a {
      display: none;
    }
    .authColumn .main-img {
      height: 284px;
    }
    flex-direction: column-reverse;
  }
`;

export const ScMainPage = styled.div`
  display: flex;
  align-items: stretch;
  min-height: 100vh;

  .mainColumn {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 16px 16px 16px 8px;
    box-sizing: border-box;
    &:first-child {
      padding: 16px 8px 16px 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .container {
      flex: 0 0 100%;
      max-width: 100%;
      min-height: calc(100% - 76px);
    }

    img {
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 32px);
      object-fit: cover;
      border-radius: 40px;
      vertical-align: bottom;
    }
  }

  @media (${media.mobile}) {
    .mainColumn {
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }
    a {
      display: none;
    }
    .mainColumn .main-img {
      height: 284px;
    }
    flex-direction: column-reverse;
  }
`;

export const ScDefaulContainer = styled.div<{
  isCollapsed: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ isCollapsed }) =>
    isCollapsed ? '96px 1fr' : '230px 1fr'};
  padding: 0;
  position: relative;
  height: 100%;

  @media (max-width: 965px) {
    grid-template-columns: 96px 1fr;
  }
`;

export const ScContainer = styled.div<{ isSmall?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 24px;

  ${({ isSmall }) =>
    isSmall &&
    css`
      min-width: 100%;
      padding: 0;
      display: block;
    `}
`;

export const ScPageWrapper = styled.div<{ isOpen?: boolean }>`
  height: 100vh;
  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 100vh;
      box-sizing: border-box;
      overflow: hidden;
    `}
`;

export const ScMobileNavbarWrapper = styled.div`
  position: relative;
`;

export const ScMobilefFoatingNavbar = styled.div`
  position: absolute;
  z-index: 100;
  height: 100vh;
`;

export const ScHeadline = styled.div<{
  alignItems?: string;
  justifyContent?: string;
}>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  gap: 10px;

  svg {
    transform: scale(1);
    margin-bottom: 1px;
    cursor: pointer;
  }

  @media (max-width: 965px) {
    margin-bottom: -8px;
  }
`;

export const ScButtonWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 0 0 100%;
  max-width: 100%;

  button {
    margin-left: 16px;
    &.first {
      svg path {
        stroke: ${theme.color.secondary};
      }
    }
  }
`;

export const PageContainer = styled.div`
  display: grid;
  align-content: flex-start;
  gap: 2rem;
`;

export const PagePaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: calc(100vh - 50px);
  margin-bottom: -20px;
`;

export const Title = styled.div`
  h1 {
    display: inline;

    @media (max-width: 1010px) {
      font-size: 22px;
    }
  }
  svg {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: -1px;
  }
`;

export const ScCardWithTabs = styled.div`
  position: relative;
  display: grid;
  gap: 0.75rem;

  padding: 1.5rem;

  background: ${theme.color.white};
  border: 1px solid ${theme.color.secondaryLight};
  box-shadow: 0 1.5rem 2.5rem rgba(120, 146, 130, 0.1);
  border-radius: 0 2rem 2rem 2rem;
`;

export const EditPencilWrapper = styled.div<{ isEditMode?: string }>`
  cursor: pointer;
  svg {
    path {
      stroke: ${({ isEditMode }) => (isEditMode ? '#d5d8de' : '#2e3e5c')};
    }
  }
`;

export const CenterButtonTitle = styled.div`
  width: 100%;
  padding: 3px 0;
`;

export const TrashIcon = styled(Trash)<{ isEditMode?: string }>`
  min-width: 24px;
  cursor: pointer;
  filter: grayscale(100%);
  path {
    fill: ${({ isEditMode }) => (isEditMode ? '#d5d8de' : '#2e3e5c')};
  }
  .gray-path {
    stroke: ${({ isEditMode }) => (isEditMode ? '#d5d8de' : '#2e3e5c')};
  }
`;

export const NoDataHeader = styled.div`
  padding: 16px 18px;
  font-size: 14px;
  color: #2e3e5c;
`;

export const HeaderIconContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const MainTabs = styled.div<{ flexWrap?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'no-wrap')};
  gap: 30px;

  button {
    display: grid;
    place-content: center;
    width: 12rem;
    height: 3rem;
    border-radius: 0.75rem;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 155%;

    &:hover {
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1);
    }
  }
`;

export const NoTitleTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 45px;
`;

export const HeaderIcon = styled.div<{ isEditMode?: boolean }>`
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  cursor: pointer;
  margin-right: -4px;

  &:hover {
    background-color: #fafafa;
  }

  svg {
    path {
      stroke: ${({ isEditMode }) => (isEditMode ? '#d5d8de' : '#2e3e5c')};
    }
  }
`;

export const HeaderTrashIcon = styled.div`
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  cursor: pointer;
  margin-right: -4px;

  &:hover {
    background-color: #fafafa;
  }
`;

export const ActionIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 72px;

  svg {
    cursor: pointer;
  }
`;

export const TabsWrapper = styled.div<{ noWrap?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 230px; */
  gap: 80px;
  padding: 0 0 0 20px;
  p {
    margin-left: -18px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 160%;
    color: #2e3e5c;
  }

  ${({ noWrap }) =>
    !noWrap &&
    css`
      @media (max-width: 1165px) {
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
      }
    `};
`;

export const TableContainer = styled.div`
  display: grid;
  gap: 0.75rem;

  padding: 6px;

  background: ${theme.color.white};
  border: 1px solid ${theme.color.secondaryLight};
  box-shadow: 0 1.5rem 2.5rem rgba(120, 146, 130, 0.1);
  border-radius: 16px;
`;

export const ScrollableTableContainer = styled.div<{
  isCollapsed?: boolean;
  isNoScroll?: boolean;
  isModal?: boolean;
}>`
  max-width: ${({ isCollapsed, isModal }) =>
    isCollapsed && isModal
      ? 'calc(100vw - 100px)'
      : isCollapsed
      ? 'calc(100vw - 200px)'
      : 'calc(100vw - 330px)'};
  width: 100%;
  overflow-x: ${({ isNoScroll, isModal }) =>
    isNoScroll ? 'hidden' : isModal ? 'unset' : 'auto'};
  overflow-y: ${({ isNoScroll, isModal }) =>
    isNoScroll ? 'hidden' : isModal ? 'unset' : 'auto'};
  white-space: nowrap;
  padding-bottom: 12px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 850px) {
    max-width: ${({ isCollapsed }) =>
      isCollapsed ? 'calc(678px)' : 'calc(100vw - 330px)'};
  }
`;

export const TableWrapper = styled.div`
  display: grid;
  gap: 0.75rem;

  padding: 6px;

  background: ${theme.color.white};
  border: 1px solid ${theme.color.secondaryLight};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
`;

export const TitleWithEmoji = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .emoji {
    font-size: 20px;
  }
`;

export const EmojiSkeleton = styled.div`
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${theme.color.secondaryLight};
  margin-left: -4px;
  border: 1px solid silver;
`;

export const TableCellDot = styled.span<{ bgColor: string }>`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-bottom: -1px;
  margin-right: 7px;
  background-color: ${({ bgColor }) => bgColor};
`;
